<template>
  <v-app>
    <Snackbar class="Snack" />
    <v-app-bar v-if="appbar" style="z-index: 2;" elevation="0" color="#fff" fixed>

      <v-container class="px-0"
        style="display: flex; align-items: top  ; gap: 10px; justify-content: space-between; width: 100%;">

        <v-avatar size="36" color="primary" to="/coupons" v-if="user && $route.name == 'home'"
          @click="bottomSheet = true">
          <v-icon small color="secondary">fa-solid fa-user</v-icon>
        </v-avatar>
        <v-avatar v-else size="36" color="secondary" @click="btnBack">
          <v-icon small dark>fa-solid fa-arrow-left</v-icon>
        </v-avatar>




        <router-link to="/home" style="position: absolute; left: 50%; transform: translateX(-50%);">
          <img src="@/assets/logo-h.svg" alt="logo" height="40" />
        </router-link>
        <v-badge color="error" :content="amountCoupons" overlap :dot="$route.name == 'couponClaimed'">
          <v-avatar size="36" color="primary" @click="toPage('/couponClaimed')">
            <img src="@/assets/tick.svg" style="object-fit: contain; width: 24px; height: 24px;" />
          </v-avatar>
        </v-badge>
      </v-container>

    </v-app-bar>

    <v-bottom-sheet v-model="bottomSheet" >
      <v-sheet class="text-center pa-4" height="400px" style="border-radius: 20px 20px 0 0;">
        <div class="d-flex justify-center flex-column align-center pt-12">
          <v-avatar size="60" color="primary">
            <v-icon size="30">fa-solid fa-user</v-icon>
          </v-avatar>
          <h3 class="text-capitalize argos-text">{{ user.name }}</h3>
          <span class="text-capitalize argos-text">{{ user.email }}</span>
          <v-btn small style="text-transform: none; border-radius: 10px;" color="secondary" class="mt-12" @click="logOut">Cerrar sesión</v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-main style="z-index: 1 ; padding-top: 60px;">
      <!-- <transition-page> -->
      <transition name="fade">
        <router-view />
      </transition>
      <!-- </transition-page> -->
    </v-main>
    <footerVue style="position: relative; margin-top: 20px;" />
    <background v-if="$route.meta.background" />
  </v-app>
</template>

<script>
import footerVue from "@/components/footer";
import Snackbar from "@/components/snackbar";
import background from "@/components/background";
import TransitionPage from "@/components/TransitionPage";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {

    Snackbar,
    background,
    TransitionPage,
    footerVue,

  },
  data: () => ({
    bottomSheet: false,
    menu: null,
    appbar: false,
    nameUser: [],
    claimedCoupons: [],
    searchShow: false,

    //
  }),
  computed: {
    ...mapState(["role", "selectedBusiness", "search", "user", "city",
      "country",]),
    firstChart() {
      if (this.nameUser.length > 0) {
        return `${this.nameUser[0].charAt(0)}.${this.nameUser[1].charAt(0)}`;
      } else {
        return "";
      }
    },
    amountCoupons() {
      return this.claimedCoupons.length;
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["setUser", "Alert_", "_country",
      "_city",]),
    logOut() {
      this.bottomSheet = false;
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.setUser(false);
            this.$router.push({
              path: "/",
            });
          });
      }
    },
    btnBack() {
      this.$router.go(-1 || "/coupons");
    },
    getHome() {
      this.$router.push("/coupons");
    },
    toPage(to) {
      this.$router.push(to);
    },
  },
  watch: {
    $route(e) {
      this.appbar = e.meta.appbar;
      this.searchShow = e.meta.searchShow;

      var rutes = ["coupons", "couponClaimed", "home"];
      if (rutes.includes(this.$route.name) && !this.user) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    fb.auth().onAuthStateChanged(async (authUser) => {
      let router = this.$router.currentRoute;
      this.appbar = router.meta.appbar;
      this.searchShow = router.meta.searchShow;


      if (authUser) {
        var currentUser = await fb.auth().currentUser.getIdTokenResult();
        await db
          .collection("users")
          .doc(currentUser.claims?.user_id)
          .onSnapshot(async (user) => {
            user = user.data();
            // console.log(currentUser.claims?.user_id)
            user[`.key`] = currentUser.claims?.user_id || null;
            this.setUser(user);


            this.UserApp = user;
            this.nameUser = user.name.split(" ");
            await this.$binding("claimedCoupons", db.collection("claimedCoupons").where("userId", "==", user[".key"]).where("status", "==", "pending"));
            if (!user.status == "pending") {
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              if (this.$router.currentRoute.name == "login") {
                this.$router.push({ path: "/home" });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                this.$router.push({ path: "/home" });
              }
            }
          });
      } else {
        this.setUser(false);
        //// console.debug(this.$route.name);
        var rutes = ["coupons", "couponClaimed"];
        if (rutes.includes(this.$route.name) && !this.user) {
          this.$router.push("/");
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.name-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  color: #171643;
}

.v-toolbar__content {
  width: 100% !important;
  display: flex;

  background-color: #171643 !important;
  justify-content: space-between;
  align-items: center;
}

.none-mobile {
  display: block;
}

.none-mobile {
  @include responsive(mobile) {
    display: none;
  }
}



.d-none-mobile {
  display: block;

  @include responsive(mobile) {
    display: none !important;

  }
}

.btn-back {}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: all .2s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(200px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out !important;
}
</style>