<template>
  <v-card>
    <v-card-title> Solicitar Comercio </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model="newItem.name" :rules="required" label="Nombre del negocio" required filled rounded dense
                  style="border-radius: 5px"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model.number="newItem.phone" :rules="required" label="Telefono" required filled type="tel"
                  rounded dense style="border-radius: 5px"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model="newItem.email" label="Correo" :rules="[rules.email]" required filled rounded dense
                  style="border-radius: 5px"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model="newItem.rtn"  label="RTN"  filled rounded dense
                  style="border-radius: 5px"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="6">
                <v-select filled rounded style="border-radius: 5px" dense label="País" :rules="required" required
                  :items="countries" v-model="newItem.countryId" item-text="name" item-value=".key"
                  @change="getCities"></v-select>
              </v-col>
            
              <v-col cols="12" md="3" lg="6">
                <v-select filled rounded style="border-radius: 5px" dense :rules="required" required label="Ciudad"
                  :items="cities" v-model="newItem.cityId" item-text="name" item-value=".key"></v-select>
              </v-col>
             
              <!-- <v-col cols="12">
                <v-textarea label="Dirección" placeholder="Ingrese la dirección del comercio" :rules="required"
                  v-model="newItem.address" required filled rounded dense style="border-radius: 5px"></v-textarea>
              </v-col> -->
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model="newItem.nameContactp" :rules="required" label="Nombre y apellido de persona de contacto" required filled rounded dense
                  style="border-radius: 5px"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="6">
                <v-text-field v-model="newItem.comment" :rules="required" label="Cuentanos sobre tu negocio" required filled rounded dense
                  style="border-radius: 5px"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :loading="loadingBtn" @click="$emit('close')">
        cancelar
      </v-btn>
      <v-btn :loading="loadingBtn" color="primary" @click="saveInfo">
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "formBusiness",
  props: ["dataEdit"],
  data() {
    return {
      imagedefaul: require("@/assets/template-01.svg"),
      loadingBtn: false,
      valid: true,
      file: null,
      preViewMap: null,
      country: null,
      city: null,
      cities: [],
      typeBusiness: [
        { 
          value: 'Concretera',
          text: 'Concretera',
        },
        { 
          value: 'Constructora',
          text: 'Constructora',
        },
        { 
          value: 'Ingeniero dependiente',
          text: 'Ingeniero dependiente',
        },
        { 
          value: 'Fabricante',
          text: 'Fabricante',
        },
        { 
          value: 'Ferreteria',
          text: 'Ferreteria',
        },
        { 
          value: 'Otro',
          text: 'Otro',
        },
      ],
      countries: [],
      newItem: {
        name: null,
        email: null,
        createdCoupons: 0,
        rtn: null,
        address: null,
        validatedRtn: false,
        cityId: null,
        countryId: null,
        phone: null,
        active: false,
        deleted: false,
        status: "pending",
      },
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      required: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["Alert_"]),
    getCities(value) {
      // console.debug(value);
      this.$binding(
        "cities",
        db.collection("countries").doc(value).collection("cities")
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    uploadImage(e) {
      this.preViewMap = "";
      let objectURL = URL.createObjectURL(e);
      this.preViewMap = objectURL;
    },

    saveInfo() {
      this.$refs.form.validate();
      this.loadingBtn = true;
      if (this.$refs.form.validate()) {
        const newItem = this.newItem;
        newItem.createdAt = new Date();
        newItem.active = true;
        newItem.status = "pending";
        // console.debug(newItem);
        db.collection("pendingBusinesses")
          .add(newItem)
          .then(async (ref) => {
            // var logoUpdate = await this.addLogo(ref.id);
            // if (logoUpdate) {
            setTimeout(() => {
              this.Alert_({
                text: "Solicitud Enviada correctamente",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "success",
              });
              this.loadingBtn = false;
              this.$emit("close");
            }, 1000);
            // }
          })
          .catch((error) => {
            this.loadingBtn = false;
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      } else {
        this.loadingBtn = false;
        this.Alert_({
          text: "Ocurrió un error inesperado, inténtelo nuevamente.",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },
    // addLogo(businesskey) {
    //   return new Promise(async (resolve, reject) => {
    //     if (this.file) {
    //       const image = this.file;
    //       //// // console.debug(image);
    //       let uploadTask = fb
    //         .app()
    //         .storage(`gs://club-argos.appspot.com/`)
    //         .ref()
    //         .child(`business/${businesskey}/${image.name}`)
    //         .put(image);
    //       await uploadTask.on(
    //         "state_changed",
    //         (snapshot) => {
    //           // this.progress =
    //           //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //         },
    //         (error) => {
    //           //// console.error(error);
    //           this.Alert_({
    //             text: "Ocurrió un error inesperado, inténtelo nuevamente.",
    //             timeout: 2000,
    //             btn_closed: true,
    //             icon: false,
    //             iconType: "mdi-check",
    //             type: "error",
    //           });
    //         },
    //         () => {
    //           uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //             //// // console.debug(downloadURL);
    //             db.collection("businesses").doc(businesskey).update({
    //               logo: downloadURL,
    //             });
    //           });
    //         }
    //       );

    //       resolve(true);
    //     }
    //   });
    // },
  },
  mounted() {
    this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    );
    if (this.dataEdit) {
      this.newItem = Object.assign({}, this.dataEdit);
      this.preViewMap = this.dataEdit.logo;
      this.$binding(
        "cities",
        db
          .collection("countries")
          .doc(this.dataEdit.countryId)
          .collection("cities")
      );
    }
  },
};
</script>
<style lang="scss">
.flex-start {
  display: flex;
  justify-content: space-evenly;
}
</style>