import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from 'vuetify/lib/locale/es';
import 'vuetify/dist/vuetify.min.css';
import Background from "@/components/background.vue";
Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },

      themes: {
        light: {
          superBackground: "#000",
          Background: "#000",
          primary: "#c6d600",
          secondary: "#001E4C",
          accent: "#c6d600",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
        },
        dark: {
          Background: "#000",
          primary: "#c6d600",
          secondary: "#001E4C",
          accent: "#c6d600",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
        },
      },
    
  },
  lang: {
    current: "es",
  },
  icons: {
    iconfont: "fa",
  },
});
