<template>
  <div class="login">



    <img src="@/assets/logo-h.svg" height="60px" />
    <div class="wrap-login-main">
      <v-card max-width="320" class="wrap-login" :loading="btnloading" elevation="0" style="border-radius: 20px;">
      <v-avatar color="primary" size="60px" class="phone-icon">
    <div>

      <img src="@/assets/phone.svg" alt="" srcset="">
    </div>
      </v-avatar>
        <v-card-title>
          <span class="text-welcome">
            <b>¡BIENVENIDO!</b> <br>
           <span class="text-welcome" v-if="!otpDialog">
            para ingresar coloca <br>
            tu número de celular
           </span>
           <span class="text-welcome" v-else>
            Se ha enviado un SMS al número <br> <strong style="color: #071e49;">+{{ code.callingCodes[0]
          }}{{ phone }}</strong>  incluyendo un código <br> numérico de 6 digítos al  número de
         <br>  celular ingresado.
          <a @click="reset" style="color: #071e49; cursor: pointer">Cambiar número</a>
           </span>
          </span>
        </v-card-title>
       
      
        <v-card-text>
          <otp :auth="authObject" v-if="otpDialog" ref="code" @cancel="(otpDialog = false), (loading = false)"
            style="border-radius: 16px;"
            @success="otpConfirmed" />
          <inputPhone v-else @retunValue="valueInput" @actions="onNext" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class=" black--text" height="56" width="100%" v-if="otpDialog"
            style="border-radius: 12px" :loading="loading" block :disabled="counter > 0" @click="onNext">
            Reenviar código <span v-if="counter > 0">({{ counter }})</span>
          </v-btn>

          <v-btn color="primary" elevation="0" class="black--text" height="46" width="100%" v-else
            style="border-radius: 12px" :loading="loading" block @click="onNext">
            Enviar código
          </v-btn>
       
        </v-card-actions>
      </v-card>
      <v-btn style="border-radius: 12px"  dark color="secondary" class="mt-4" @click="dialogBePartOfClub = true">
            Quiero ser parte del club
          </v-btn>


    </div>


    <v-dialog v-model="dialogBePartOfClub" v-if="dialogBePartOfClub" width="600" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title>
          Se parte del club
          <v-btn elevation="0" right absolute fab small @click="dialogBePartOfClub = false">
            <v-icon> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-img @click="setType('client')" class="" style="border-radius: 10px" src="@/assets/client-01.svg">
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-img @click="setType('business')" class="" style="border-radius: 10px" src="@/assets/client-02.svg">
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent scrollable v-model="businessDialog" width="800" v-if="businessDialog">
      <formBusiness @close="businessDialog = false" />
    </v-dialog>
    <v-dialog persistent scrollable v-model="clientDialog" width="400" v-if="clientDialog">
      <formValidationClient @close="clientDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import formBusiness from "@/components/businessFormValidations.vue";
import formValidationClient from "@/components/formValidationClient.vue";
import inputPhone from "@/components/inputPhone.vue";
import alertMessage from "@/mixins/alertMixin";
import otp from "@/components/insertCode.vue";
export default {
  name: "Login",
  components: {
    formBusiness,
    formValidationClient,
    inputPhone,
    otp,
  },
  mixins: [alertMessage],
  data() {
    return {
      businessDialog: false,
      clientDialog: false,
      dialogBePartOfClub: false,
      valid: true,
      loading: false,
      dni: null,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      counter: 0,
      phone: null,
      code: null,
      btnloading: false,
      categories: [
        require('@/assets/categories/categoria-06.svg'),
        require('@/assets/categories/categoria-07.svg'),
        require('@/assets/categories/categoria-08.svg'),
        require('@/assets/categories/categoria-09.svg'),
        require('@/assets/categories/categoria-10.svg'),
        require('@/assets/categories/categoria-11.svg'),
        require('@/assets/categories/categoria-12.svg'),
        require('@/assets/categories/categoria-13.svg'),
        require('@/assets/categories/categoria-14.svg'),
        require('@/assets/categories/categoria-15.svg'),
      ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        number: (value) =>
          /^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/.test(value) || "Solo numeros",
        min: (v) => (v && v.length >= 13) || "Mínimo 13 caracteres",
      },
    };
  },
  methods: {
    ...mapActions(["Alert_"]),
    setType(type) {
      // console.log(type);
      this.dialogBePartOfClub = false;



      if (type == "business") {
        this.businessDialog = true;

      }
      if (type == "client") {
        this.clientDialog = true;

      }
    },
    alertMessage(type, message) {
      this.Alert_({
        text: message,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: type,
      });
    },
    // isNumber: function (evt) {
    //   evt = evt ? evt : window.event;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    //   if (
    //     charCode > 31 &&
    //    (charCode < 48 || charCode > 57) &&
    //     (charCode >= 96 || charCode <= 105) &&
    //     charCode !== 46 &&
    //     charCode !== 110
    //   ) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    otpConfirmed(otp) {
      // this.otpDialog = false;

      var phone = `${this.code.callingCodes[0]}${this.phone}`;

      var datos = {
        phone: phone,
        otp: otp,
      };
      var usersCreateCustomToken = fb
        .functions()
        .httpsCallable("usersCreateCustomToken");
      usersCreateCustomToken(datos)
        .then((response) => {
          this.singInToken(response.data.customToken);
        })
        .catch((error) => {
          this.alertMessage("error", error.message);
          this.$refs.code.loading = false;
          this.$refs.code.otp = "";
        });
    },
    valueInput(e, code) {
      this.phone = e;
      this.code = code;
    },

    success(e) {
      this.userDialog = false;
      this.alertMessage("success", "Usuario registrado exitosamente.");
    }, onNext() {
      this.loading = true;
      if (this.phone != null) {
        this.mfaLogin();
      } else {
        this.loading = false;
        this.alertMessage("error", "😬 Ingrese el campo obligatorio");
      }
    },
    mfaLogin() {
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = {
        phone: phone,
      };

      var usersSendOtp = fb
        .functions()
        .httpsCallable("usersSendOtp");

      usersSendOtp(datos).then(() => {
        this.otpDialog = true;
        this.loading = false;
        this.counter = 60;

        let timer = setInterval(() => {
          this.counter--;

          if (this.counter == 0) {
            clearInterval(timer);
          }
        }, 1000);
      }).catch(error => {
        this.alertMessage("error", error.message);
        this.loading = false;
      })
    },
    singInToken(token) {
      fb.auth()
        .signInWithCustomToken(token)
        .then(async (userCredential) => {
          // console.debug("userCredential", userCredential);

          this.$emit("closed");
          this.$emit("NextOrder");
          // setTimeout(() => {
          //   this.loading = false;
          //   this.$router.push("/store");
          // }, 1000);
          // ...
        })
        .catch((error) => {
          this.alertMessage("error", error.message);
          this.loading = false;

          // //// console.debug(error);
        });
    },
    reset() {
      this.phone = "";
      this.otpDialog = false;
    },

    // onNext() {
    //   this.btnloading = true;
    //   if (this.dni != null) {
    //     var usersCreateCustomToken = fb
    //       .functions()
    //       .httpsCallable("usersCreateCustomToken");
    //     var data = {
    //       dni: this.dni.replace("-", ""),
    //     };
    //     usersCreateCustomToken(data)
    //       .then(async (response) => {
    //         //// console.debug(response);
    //         await fb.auth().signInWithCustomToken(response.data.customToken);
    //       })
    //       .catch((error) => {
    //         this.alertMessage("error", error.message);
    //         this.dni = null;
    //         this.btnloading = false;
    //       });
    //   } else {
    //     this.btnloading = false;
    //     this.alertMessage("error", "Llena los campos seleccionados 😬");
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  gap: 24px;

  .wrap-login-main {
    width: 100%;

        background-image: url('@/assets/fonndo.jpg');
        background-size: cover;
        background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px;
    

    .phone-icon{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 10px 10px 2px  #b8c5037f;
    }
  }

  @include responsive(mobile) {}

}


.text-welcome{

  margin-top: 30px;
  text-align: center;
  line-height: 20px;
  color: #878A88;
  font-size: 12px;
  width: 100%;
  text-transform: uppercase;
  b{
    font-family: 'argos-bold';
    font-size: 24px;
  }
}
</style>
