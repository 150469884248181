<template>
  <v-card  style="border-radius: 20px;">
    <v-card-title> Registrar Cliente </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-3">
          <v-col cols="12" sm="12" md="12">
            <v-text-field rounded filled style="border-radius: 10px" label="Ingrese el nombre y apellido" dense :rules="required" required
              v-model="newUser.name"  />
          </v-col>
          <v-col cols="12" md="3" lg="12">
              <v-select filled rounded style="border-radius: 5px" dense label="Tipo de negocio" :rules="required" required
                :items="typeBusiness" v-model="newUser.typeBusiness"></v-select>
            </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field rounded filled style="border-radius: 10px" label="Nombre del negocio" dense :rules="required" required
              v-model="newUser.businessName" placeholder=" " />
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field rounded filled style="border-radius: 10px" label="Cargo dentro de la empresa" dense :rules="required" required
              v-model="newUser.positionWithinBusiness" />
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <inputPhone @retunValue="valueInput" />
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-text-field rounded style="border-radius: 10px" filled dense :rules="required" required label="DNI"
              v-model="newUser.dni" placeholder="0801199912345" />
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field rounded style="border-radius: 10px" filled dense :rules="requiredEmail" required label="Correo"
              v-model="newUser.email" placeholder="ejemplo@server.com" />
          </v-col>

          <v-col cols="12" md="3" lg="6">
            <v-select filled rounded style="border-radius: 5px" dense :rules="required" required label="País"
              :items="countries" v-model="newUser.countryId" item-text="name" item-value=".key"
              @change="getCities"></v-select>
          </v-col>
          <v-col cols="12" md="3" lg="6">
            <v-select filled rounded style="border-radius: 5px" dense :rules="required" required
              :disabled="!newUser.countryId" label="Ciudad" :items="cities" v-model="newUser.cityId" item-text="name"
              item-value=".key"></v-select>
           
          </v-col>
        
            <v-col cols="12" md="3" lg="12" v-if="newUser.typeBusiness == 'Otro'">
              <v-text-field  v-model="newUser.other" :rules="required"
                label="Que tipo de negocio?" required filled rounded dense style="border-radius: 5px"></v-text-field>
            </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :loading="loadingBtn" @click="$emit('close')">
        cancelar
      </v-btn>
      <v-btn style="border-radius: 10px;" class="px-4" :loading="loadingBtn" color="primary" @click="saveInfo">
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import inputPhone from "./inputPhone.vue";
import { db, fb } from "@/firebase";
export default {
  name: "formValidationClient",
  components: {
    inputPhone,
  },
  data() {
    return {
      codeArea: null,
      newUser: {},
      valid: true,
      countries: [],
      loadingBtn: false,
      cities: [],
      typeBusiness: [
        {
          value: 'Concretera',
          text: 'Concretera',
        },
        {
          value: 'Constructora',
          text: 'Constructora',
        },
        {
          value: 'Ingeniero dependiente',
          text: 'Ingeniero dependiente',
        },
        {
          value: 'Fabricante',
          text: 'Fabricante',
        },
        {
          value: 'Ferreteria',
          text: 'Ferreteria',
        },
        {
          value: 'Otro',
          text: 'Otro',
        },
      ],
      requiredEmail: [
        (v) => /.+@.+\..+/.test(v) || "Correo inválido",
        (v) => !!v || "Campo requerido",
      ],
      required: [(v) => !!v || "Campo requerido"],
    };
  },
  methods: {
    ...mapActions(["Alert_"]),
    valueInput(e, code) {
      // //// console.debug(e, code);
      this.newUser.phone = e;
      this.codeArea = code;
    },
    getCities(value) {
      // console.debug(value);
      this.$binding(
        "cities",
        db.collection("countries").doc(value).collection("cities")
      );
    },
    saveInfo() {
      this.$refs.form.validate();
      this.loadingBtn = true;
      if (this.$refs.form.validate()) {
        const newItem = this.newUser;
        newItem.createdAt = new Date();
        newItem.phone = `+${this.codeArea.callingCodes[0]}${newItem.phone}`
        newItem.status = "pending";
        newItem.deleted = false;
        // console.debug(newItem);
        db.collection("clientPending")
          .add(newItem)
          .then(async (ref) => {
            setTimeout(() => {
              this.Alert_({
                text: "Solicitud Enviada correctamente",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "success",
              });
              this.loadingBtn = false;
              this.$emit("close");
            }, 1000);
          })
          .catch((error) => {
            this.loadingBtn = false;
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      } else {
        this.loadingBtn = false;
        this.Alert_({
          text: "Ocurrió un error inesperado, inténtelo nuevamente.",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    );
  },
};
</script>